@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTREGULARTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 1.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 2.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 3.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 4.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 5.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 6.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 7.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL 8.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTVARIABLETRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 400;
    src: local('Bogart'), url('./BOGARTITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 100;
    src: local('Bogart'), url('./BOGARTTHINITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 200;
    src: local('Bogart'), url('./BOGARTEXTRALIGHTTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 200;
    src: local('Bogart'), url('./BOGARTEXTRALIGHTITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 300;
    src: local('Bogart'), url('./BOGARTLIGHTTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 500;
    src: local('Bogart'), url('./BOGARTMEDIUMTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 500;
    src: local('Bogart'), url('./BOGARTMEDIUMITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 600;
    src: local('Bogart'), url('./BOGARTSEMIBOLDITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 700;
    src: local('Bogart'), url('./BOGARTBOLDTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 700;
    src: local('Bogart'), url('./BOGARTBOLDITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 800;
    src: local('Bogart'), url('./BOGARTEXTRABOLDTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 800;
    src: local('Bogart'), url('./BOGARTEXTRABOLDITALICTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 900;
    src: local('Bogart'), url('./BOGARTBLACKTRIAL.woff') format('woff');
}
@font-face {
    font-family: 'Bogart';
    font-style: italic;
    font-weight: 900;
    src: local('Bogart'), url('./BOGARTBLACKITALICTRIAL.woff') format('woff');
}